import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Checkout_CT from './Checkout_CT';
// import { fetchActiveTickets } from '../../../redux/actions/eventActions';
// import { connect } from 'react-redux';
// import { get } from 'lodash';

const Checkout_C = ({
  handleOpen,
  isOpen,
  handleClose,
  event_Dates,
  handleEventDateChange,
  ticket,
  // fetchActiveTickets,
  // activeTickets,
}) => {
  const cancelButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleCloseCT = () => {
    setSelectedDate(null);
    handleClose();
  };

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };

  const handleDateChange = (date) => {
    console.log(date);
    const selectedDateTime = new Date(date);
    selectedDateTime.setHours(5);
    selectedDateTime.setMinutes(30);
    selectedDateTime.setSeconds(0);
    // console.log(selectedDateTime);
    console.log(selectedDateTime);
    setSelectedDate(selectedDateTime);
    handleEventDateChange(selectedDateTime);
    // fetchActiveTickets( {date: selectedDateTime});
  };
  // console.log(selectedDate);
  // console.log(activeTickets)

  const tileDisabled = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
      return true;
    }
    return !event_Dates.some((timestamp) => new Date(parseInt(timestamp)).toDateString() === date.toDateString());
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="z-19 fixed inset-0" initialFocus={cancelButtonRef} onClose={handleClose}>
          <div className="flex min-h-screen items-center justify-center p-0 px-4 pb-20 pt-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative mx-auto my-8 w-full max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                <div className="p-6">
                  <h1 className="mb-4 text-center text-xl font-semibold">Active Dates Calendar</h1>
                  <div className="mb-4 flex justify-center">
                    <Calendar
                      onChange={handleDateChange}
                      value={selectedDate}
                      tileDisabled={tileDisabled}
                      className="rounded-md border border-gray-200"
                    />
                  </div>
                  {selectedDate && (
                    <div className="mt-6">
                      <Checkout_CT
                        handleOpen={handleOpen}
                        handleClose={handleCloseCT}
                        date={selectedDate}
                        ///                        isOpen={true}
                        ticket={ticket}
                      />
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="block w-full bg-red-500 py-3 text-sm font-semibold text-white hover:bg-red-600"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Checkout_C;
// const mapStateToProps = (state) => ({
//   activeTickets: get(state, 'event.activeTickets', {}),
// });
// export default connect(mapStateToProps, {
// //  fetchActiveTickets,
// })(Checkout_C);
