import React, { useState, useEffect, useMemo } from 'react';
import { FaBarcode, FaBuilding, FaGlobe, FaMinus, FaPlus } from 'react-icons/fa6';
import { hero } from '../../data';
import { FaCalendarAlt, FaPlay } from 'react-icons/fa';
import AddressDialog from './AddressDialog';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { load } from '@cashfreepayments/cashfree-js';
import encyclopedia from '../../Images/curiobit/encyclopedia.png';
import demobookcover from '../../Images/curiobit/video_.mp4';
import usebook from '../../Images/curiobit/use-book.jpg';
import frontcover from '../../Images/curiobit/front-cover.jpg';
import backcover from '../../Images/curiobit/back-cover.jpg';
import product from '../../Images/curiobit/Product.png';
import './product.css';
import api from '../../utils/instance';
import { Helmet } from 'react-helmet-async';

import { PASSES_ALL_CHECK_D2C, PAYMENT, SEAT_VERIFY_D2C } from '../../config/endPoints';
import NewUser from '../NewUser';
import instance from '../../utils/instance';
import { useDispatch } from 'react-redux';
import { setOrderId } from '../../redux/actions/userActions';

const Product_Page = ({ handleOpen, user, isAuthenticated, token }) => {
  const [eventInfo, setEventInfo] = useState(null);
  const NODE_ENV = process.env.REACT_APP_NODE_ENV;
  const [cashfree, setCashfree] = useState(null);
  const [largeImage, setLargeImage] = useState(encyclopedia);
  const [smallImages, setSmallImages] = useState([demobookcover, usebook, frontcover, backcover]);
  const [quantity, setQuantity] = useState(1);
  const [bookPrice, setBookPrice] = useState(eventInfo?.ticketPrice || 0);
  const [discountedPrice, setDiscountedPrice] = useState(bookPrice);
  const [couponCode, setCouponCode] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');
  const [questionClose, setQuestionClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [seatVerify, setSeatVerify] = useState(null);
  const [newUser, setNewUser] = useState(false);
  const [loginUser, setLoginUser] = useState(false);

  const dispatch = useDispatch();
  console.warn(seatVerify);
  const eventCode = 'concise-encyclopedia';
  const BASE = process.env.REACT_APP_BASE;
  useEffect(() => {
    const fetchEventInfo = async () => {
      try {
        const response = await api.get(`${BASE}/event/fetch-event-info`, {
          params: { eventCode },
        });
        setEventInfo(response.data);
        if (!isCouponApplied) {
          setDeliveryFee(response.data.convenienceFee || 0);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEventInfo();
  }, [eventCode, isCouponApplied]);
  useEffect(() => {
    if (eventInfo) {
      setBookPrice(eventInfo?.ticketPrice || 0);
      setDeliveryFee(eventInfo?.convenienceFee || 0);
    }
  }, [eventInfo]);

  const initializeSDK = async () => {
    try {
      let env;
      if (NODE_ENV == 'production') {
        env = 'production';
      } else {
        env = 'sandbox';
      }
      const loadedCashfree = await load({ mode: env });
      setCashfree(loadedCashfree);
    } catch (error) {
      console.error('Failed to initialize Cashfree SDK:', error);
    }
  };
  useEffect(() => {
    initializeSDK();
  }, []);

  const handleQuestionClose = async () => {
    setQuestionClose(false);
  };

  const handleImageClick = (index) => {
    const newLargeImage = smallImages[index];
    const newSmallImages = [...smallImages];
    newSmallImages[index] = largeImage;
    setLargeImage(newLargeImage);
    setSmallImages(newSmallImages);
  };

  const renderMedia = (src, isLarge = false) => {
    const isVideo = src.endsWith('.mp4');
    return isVideo ? (
      <div className="relative size-full">
        <video className="size-full object-contain " controls={isLarge}>
          <source src={src} type="video/mp4" />
        </video>
        {!isLarge && (
          <div className="absolute inset-0 flex items-center justify-center">
            <FaPlay className="text-3xl text-white" />
          </div>
        )}
      </div>
    ) : (
      <img src={src} alt="small media" className="size-full object-contain" />
    );
  };

  const handleQuantityChange = (type) => {
    if (type === 'increment') {
      setQuantity(quantity + 1);
    } else if (type === 'decrement' && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleNewUserClose = () => {
    setNewUser(false);
  };

  useEffect(() => {
    if (isAuthenticated && token) {
      if (!user?.email || !user?.name) {
        setNewUser(true);
      }
    }
  }, [user, isAuthenticated, token]);
  const initialCouponCode = 'CURIOFYDO';
  const handleApplyCoupon = () => {
    if (couponCode === 'CURIOFYDO') {
      const discounted = 845;
      setDiscountedPrice(discounted);
      setIsCouponApplied(true);
      setCouponMessage('Coupon Applied Successfully');
      //      setDeliveryFee(0);
    } else {
      setIsCouponApplied(false);
      setCouponMessage('Invalid Coupon');
      //    setDeliveryFee(eventInfo?.convenienceFee);
    }
  };
  useEffect(() => {
    if (couponCode) {
      handleApplyCoupon();
    }
  }, [couponCode, handleApplyCoupon]);
  useEffect(() => {
    if (initialCouponCode) {
      setCouponCode(initialCouponCode);
      handleApplyCoupon(); // Call to apply coupon on load
    }
  }, [initialCouponCode, handleApplyCoupon]);

  const handleAddressSubmit = (submittedData) => {
    handleBuyNow(submittedData);
  };

  const handleBuyNowQuestion = () => {
    if (!isAuthenticated && !token && !user) {
      handleOpen();
      setLoginUser(true);
    } else {
      if (!isEmpty(eventInfo) && quantity) {
        setLoginUser(true);
      }
    }
  };

  const shouldCloseQuestion = useMemo(() => {
    return loginUser && !isEmpty(eventInfo) && quantity && isAuthenticated;
  }, [loginUser, eventInfo, quantity, isAuthenticated]);

  useEffect(() => {
    if (shouldCloseQuestion) {
      setQuestionClose(true);
    }
  }, [shouldCloseQuestion]);

  const handleBuyNow = async (submittedData) => {
    const calculateTotalAmount = () => {
      const totalPrice = isCouponApplied ? discountedPrice * quantity : bookPrice * quantity;
      //const newDeliveryFee = isCouponApplied ? 0 : deliveryFee;
      return {
        totalPrice,
        //newDeliveryFee,
        totalPayAmount: totalPrice + deliveryFee,
      };
    };

    const { totalPrice, totalPayAmount } = calculateTotalAmount();

    // if (!isAuthenticated && !token && !user) {
    //   handleOpen();
    //   return;
    // }

    setLoading(true);

    const createRequestData = () => ({
      originalAmount: totalPrice,
      paymentAmount: totalPayAmount,
      deliveryFee,
      eventId: eventInfo?._id,
      eventName: eventInfo?.name,
      totalQuantity: quantity,
      selfPart: totalPayAmount,
      ticketTypeArray: eventInfo?.ticketTypes.map(({ code, name }) => ({ code, name, quantity })),
      extraQuestions: submittedData,
      couponUsed: isCouponApplied,
      couponCode: isCouponApplied ? couponCode : '',
    });

    const requestData = createRequestData();

    try {
      // Validate all checks
      const checksResponse = await instance.post(PASSES_ALL_CHECK_D2C, requestData);
      if (!checksResponse?.data?.passedChecks) {
        console.error('Failed checks. Reloading...');
        window.location.reload();
        return;
      }

      // Seat verification
      const seatVerifyResponse = await instance.post(SEAT_VERIFY_D2C, requestData);
      setSeatVerify(seatVerifyResponse.data);

      if (!seatVerifyResponse?.data?.transactionValid) {
        console.error('Payment verification failed');
        return;
      }

      // Process Cashfree payments
      if (seatVerifyResponse?.data?.paymentGateway === 'CASHFREE') {
        await handleCashfreePayment(seatVerifyResponse?.data);
      }
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCashfreePayment = async (paymentData) => {
    const { paymentSessionId: sessionId, transactionId: orderId } = paymentData;

    if (!sessionId || !orderId) {
      console.error('Session ID or Order ID missing');
      return;
    }

    dispatch(setOrderId(orderId));

    const checkoutOptions = {
      paymentSessionId: sessionId,
      redirectTarget: '_modal',
    };

    try {
      cashfree.checkout(checkoutOptions).then(async (result) => {
        if (result.error) {
          console.error('Payment error:', result.error);
          //          await verifyPayment(orderId);

          // window.location.reload();
          return;
        }

        if (result.redirect) {
          await verifyPayment(orderId);
        }

        if (result.paymentDetails) {
          await verifyPayment(orderId);
        }
      });
    } catch (error) {
      console.error('Cashfree payment error:', error);
    }
  };

  const verifyPayment = async (orderId) => {
    try {
      const paymentVerified = await instance.get(`${PAYMENT}${orderId}`);
      if (paymentVerified?.data?.order?.status === 'PAID') {
        //      window.open(`/test/curiobit/paymentstatus`, '_self');
        window.location.href = `/curiobit/paymentstatus/${orderId}`;
      } else {
        console.error('Payment verification failed');
        //        window.open(`/test/curiobit/paymentstatus`, '_self');
        window.location.href = `/curiobit/paymentstatus/${orderId}`;

        // window.location.reload();
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };
  // console.warn(largeImage);
  const title = eventInfo?.name ? `${eventInfo.name} - Curiobit` : 'Curiobit concise encyclopedia - Curiobit';
  const defaultKeywords = 'Curiobit, Encyclopedia, Curiobit concise encyclopedia, Book, Children book, AR, Animation';

  return (
    <div className="relative mx-auto my-10 flex max-w-[1300px] flex-col items-start justify-center pb-10 md:py-10">
      <Helmet>
        <title>{title} </title>
        <meta name="keywords" content={defaultKeywords} />
        <meta property="og:title" content={eventInfo?.name} />
        <meta property="og:url" content="https://greet.fydo.in/curiobit" />
        <meta property="og:description" content={eventInfo?.description} />
        <meta property="og:image" content={eventInfo?.eventMainImageUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Greet" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="flex flex-col items-center justify-center lg:flex-row lg:items-start lg:px-10 xl:px-0">
        <div className="flex w-full flex-col items-center justify-center md:w-[70%] lg:w-[70%] xl:flex-row">
          <div className=" hidden flex-row items-center  justify-center gap-4 lg:pt-20 xl:flex xl:flex-col ">
            {/* Small Squares */}
            {smallImages.map((img, index) => (
              <div
                key={index}
                className="relative size-16 cursor-pointer bg-cover bg-center p-1 md:size-20 md:p-2"
                style={{ backgroundImage: `url(${product})` }}
                onClick={() => handleImageClick(index)}
              >
                {renderMedia(img)}
              </div>
            ))}
          </div>
          {/* Large Square */}
          <div className="lg:ml-8">
            <div
              className={`relative mb-10 h-[400px] w-[300px] bg-cover bg-center p-4 md:size-[600px] lg:h-[650px] lg:w-[500px] 
      ${largeImage === encyclopedia ? 'floating-book-a -mb-20' : ''} 
      ${
        largeImage === demobookcover
          ? 'aspect-[25/10] h-[400px] w-[300px] bg-cover bg-center p-4 md:size-[600px] lg:h-[650px] lg:w-[500px]'
          : ''
      }`}
              style={largeImage !== encyclopedia ? { backgroundImage: `url(${product})` } : {}}
            >
              {renderMedia(largeImage, true)}
              {largeImage.endsWith('.mp4') && (
                <div className="absolute right-2 top-2 cursor-pointer text-lg text-white ">&#x22EE;</div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-4 lg:pt-20 xl:hidden xl:flex-col ">
            {/* Small Squares */}
            {smallImages.map((img, index) => (
              <div
                key={index}
                className="relative size-16 cursor-pointer bg-cover bg-center p-1 md:size-20 md:p-2"
                style={{ backgroundImage: `url(${product})` }}
                onClick={() => handleImageClick(index)}
              >
                {renderMedia(img)}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-5 flex w-full flex-col items-center justify-center md:w-[80%] md:items-start md:pl-5 lg:w-[70%]">
          <h1
            className={`${hero.head} text-center text-[24px] text-[#FDF4DF] mm:text-[28px] lm:text-[34px] md:text-left md:text-[35px] lg:text-[45px] xl:text-[60px] `}
            style={{ textShadow: '3px 3px 1px #7F4A05' }}
          >
            Curiobit concise encyclopedia
          </h1>
          <span className="flex text-wrap px-3 pt-6  text-center font-montserrat text-[14px] font-normal text-[#FDF4DF] md:px-0 md:text-left md:text-[18px]">
            The Curiobit Concise Encyclopedia is a delightful introduction to the fascinating world of knowledge.
            Perfectly designed for young learners, this encyclopedia combines education with fun, making it an excellent
            resource for children to satisfy their curiosity and expand their understanding of the world.
          </span>

          {/* Bullet Points */}
          <ul className="mt-4 list-inside list-disc  space-y-2 text-wrap px-3 text-left font-montserrat text-[14px] font-normal text-[#FDF4DF] md:px-0 md:text-[18px]">
            <li>
              <span className="font-semibold">Knowledge: </span>Interactive and Interesting
            </li>
            <li>
              <span className="font-semibold">See the magic: </span>No-touch Interaction
            </li>
            <li>
              <span className="font-semibold">Dive into: </span>Future Technologies
            </li>
            <li>
              <span className="font-semibold">Healthy habits: </span>Interactive Eye and Neck Exercise
            </li>
            <li>
              <span className="font-semibold">Organize and achieve: </span>Planner and To-do List
            </li>
          </ul>

          {/* Price Section */}
          <div className="mt-6 flex flex-col items-center justify-center gap-4 font-montserrat md:items-start md:justify-start">
            <div className="text-center text-[30px] font-semibold text-[#FDF4DF] md:text-left md:text-[40px]">
              {isCouponApplied ? (
                <>
                  <span className="ml-2 ">
                    <span className="line-through"> ₹ {bookPrice?.toFixed(2)}</span>
                    {quantity > 1 && <span className="ml-1 align-bottom text-[16px] md:text-[22px]">x{quantity}</span>}
                  </span>
                  <span className="ml-2 text-[18px] text-[#ff0000] md:text-[26px]">
                    {/* - INTROOFFER */}-{((1 - discountedPrice / bookPrice) * 100)?.toFixed(0)}%
                  </span>
                  <br />
                  <span className="ml-2 text-[#FFB700]">
                    ₹ {discountedPrice?.toFixed(2)}
                    {quantity > 1 && <span className="ml-1 align-bottom text-[16px] md:text-[22px]">x{quantity}</span>}
                  </span>
                </>
              ) : (
                <span>
                  ₹ {bookPrice?.toFixed(2)}
                  {quantity > 1 && <span className="ml-1 align-bottom text-[16px] md:text-[22px]">x{quantity}</span>}
                </span>
              )}
            </div>
            <div className="text-wrap text-left font-montserrat text-[14px] font-semibold text-[#FDF4DF] md:text-[18px]">
              {isCouponApplied ? (
                <div>
                  <div>
                    <span className="text-[16px] font-bold text-[#FFB700]">+</span> Delivery Charge: ₹ {deliveryFee}
                  </div>
                  {/* 
                  <div className="line-through">Delivery Charge: ₹ {eventInfo?.convenienceFee}</div>
                  <div className="text-[#FFB700]">Delivery Charge: FREE</div> */}
                </div>
              ) : (
                <>
                  {deliveryFee > 0 ? (
                    <>
                      <div>
                        <span className="text-[16px] font-bold text-[#FFB700]">+</span> Delivery Charge: ₹ {deliveryFee}
                      </div>
                    </>
                  ) : (
                    <div>Delivery Charge: FREE</div>
                  )}
                </>
              )}
            </div>
            {/* Quantity Control */}
            <div className="flex items-center gap-3 text-[18px]">
              <button
                onClick={() => handleQuantityChange('decrement')}
                style={{
                  background: 'linear-gradient(90deg, #F9D730 0%, #FDBE0F 44.16%, #FFB200 100%)',
                }}
                className="rounded-lg  p-1 font-montserrat text-xl font-bold text-[#1A1A1A] hover:bg-[#FFB219]"
              >
                <FaMinus className="size-5" />
              </button>
              <input
                title="quantity"
                type="number"
                className="flex h-10 w-12 items-center justify-center rounded-2xl bg-gray-200 bg-opacity-10 text-center font-montserrat text-xl  font-semibold text-white"
                value={quantity}
                readOnly
              />
              <button
                onClick={() => handleQuantityChange('increment')}
                style={{
                  background: 'linear-gradient(90deg, #F9D730 0%, #FDBE0F 44.16%, #FFB200 100%)',
                }}
                className="rounded-lg  p-1 font-montserrat text-xl font-bold text-[#1A1A1A] hover:bg-[#FFB219]"
              >
                <FaPlus className="size-5" />
              </button>
            </div>
          </div>

          {/* Coupon Code */}
          <div className="mt-4 hidden flex-col items-center gap-4 font-montserrat text-[18px] md:flex-row">
            <input
              type="text"
              placeholder="Enter coupon code"
              className="w-full rounded border border-[#FDF4DF] px-4 py-2 text-black md:w-3/4 lg:w-3/4"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button
              onClick={handleApplyCoupon}
              style={{
                background: 'linear-gradient(90deg, #F9D730 0%, #FDBE0F 44.16%, #FFB200 100%)',
              }}
              className="rounded-lg px-4  py-2 font-montserrat text-xl font-bold text-[#1A1A1A] hover:bg-[#FFB219]"
            >
              Apply
            </button>
          </div>

          {/* Coupon Message */}
          {couponMessage && <div className="mt-2 hidden font-montserrat text-[#FDF4DF]">{couponMessage}</div>}

          {/* Buy Button */}
          <button
            onClick={handleBuyNowQuestion}
            style={{
              background: 'linear-gradient(90deg, #F9D730 0%, #FDBE0F 44.16%, #FFB200 100%)',
            }}
            className={`mt-5 rounded-lg px-4 py-2 font-montserrat text-xl font-bold text-[#1A1A1A] hover:bg-[#FFB219] ${
              loading ? 'cursor-not-allowed opacity-50' : ''
            }`}
            disabled={loading}
          >
            {' '}
            {loading ? 'Processing...' : 'Buy Now'}
          </button>
          <div className="grid grid-cols-1 items-start justify-center gap-6 pt-10 sm:grid-cols-2 lg:grid-cols-4">
            {/* Publisher */}
            <a
              href="https://curiobit.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-center"
            >
              <span className="text-4xl text-[#FFB300]">
                <FaBuilding />
              </span>
              <h6 className="pt-4 text-lg font-semibold text-[#FFB300]">Publisher</h6>
              <p className="text-[14px] text-[#FDF4DF]">Curiobit Technologies Private Limited</p>
            </a>

            {/* Language */}
            <div className="flex flex-col items-center text-center">
              <span className="text-4xl text-[#FFB300]">
                <FaGlobe />
              </span>
              <h6 className="pt-4 text-lg font-semibold text-[#FFB300]">Language</h6>
              <p className="text-[14px] text-[#FDF4DF]">English</p>
            </div>

            {/* Publication Date */}
            <div className="flex flex-col items-center text-center">
              <span className="text-4xl text-[#FFB300]">
                <FaCalendarAlt />
              </span>
              <h6 className="pt-4 text-lg font-semibold text-[#FFB300]">Publication Date</h6>
              <p className="text-[14px] text-[#FDF4DF]">January 1, 2025</p>
            </div>

            {/* ISBN-13 */}
            <div className="flex flex-col items-center text-center">
              <span className="text-4xl text-[#FFB300]">
                <FaBarcode />
              </span>
              <h6 className="pt-4 text-lg font-semibold text-[#FFB300]">ISBN-13</h6>
              <p className="text-[14px] text-[#FDF4DF]">9788197920554</p>
            </div>
          </div>
        </div>
      </div>
      {/* Specifications */}
      <div className="my-10 flex w-full flex-col items-center justify-center px-10 pt-10 md:items-start md:px-20 lg:px-10">
        <h5 className={`items-start text-center  text-xl font-semibold text-[#FFB300] md:text-left `}>
          Specifications
        </h5>
        <span className="flex text-wrap pt-6 text-justify font-montserrat text-[16px] font-normal text-[#FDF4DF] md:text-[18px]">
          Ignite the spark of curiosity in young minds with the Curiobit Concise Encyclopedia, a vibrant and engaging
          resource that brings the wonders of the world to life. This encyclopedia combines captivating stories,
          fascinating facts, and vivid illustrations to cover a wide range of topics such as animals, space, history,
          science, and global cultures. Written in simple, accessible language, it empowers readers to explore their
          interests, discover new knowledge, and enjoy the joy of learning independently.
        </span>
      </div>
      {questionClose && (
        <AddressDialog
          isOpen={questionClose}
          eventInfo={eventInfo}
          handleClose={handleQuestionClose}
          onAddressSubmit={handleAddressSubmit}
        />
      )}
      {newUser && <NewUser isOpen={newUser} handleClose={handleNewUserClose} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: get(state, 'auth.token', null),
  isAuthenticated: get(state, 'auth.isAuthenticated', false),
  user: get(state, 'auth.user', null),
});
export default connect(mapStateToProps)(Product_Page);
