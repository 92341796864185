import React from 'react';
import HeroPhone from '../../../Images/merchant/hero-phone.png';
import HeroImg from '../../../Images/merchant/heroimg.jpg';
import { CTImage, CTWrapper } from '../../common';
// import EventForm from '../EventForm';
//import { Link } from 'react-router-dom';
const HeroSection = () => {
  // const [isFormOpen, setIsFormOpen] = useState(false);

  // const openForm = () => {
  //   setIsFormOpen(true);
  // };

  // const closeForm = () => {
  //   setIsFormOpen(false);
  // };
  const handleClick = () => {
    window.location.href = 'https://greet-dashboard.fydo.in/auth/login';
  };
  return (
    <div
      className="bg-cover bg-center object-cover pt-12 lg:-mt-[32px] lg:pt-0"
      style={{ backgroundImage: `url(${HeroImg})` }}
    >
      <CTWrapper className="container ">
        <div className="flex flex-wrap items-center">
          <div className="w-full text-center md:mt-0 md:w-7/12 md:text-left">
            <div className="mb-12 text-white lg:mb-0 lg:max-w-[570px]">
              <p className="text-2xl font-bold md:text-3xl lg:text-5xl lg:leading-[56px] xl:text-6xl xl:leading-[65px]">
                Effortlessly go live with your event in a go!
              </p>
              <p className="mt-4 text-xs font-normal md:mt-2 lg:mt-8 lg:text-sm">
                Empower your events, whether on-ground or digital, with our user-friendly platform loved by millions,
                making event creation and hosting a breeze.
              </p>
              {/* <Link to="/organiser/list-your-event"> */}
              <button
                onClick={handleClick}
                //                onClick={openForm}
                className="mt-10 w-full rounded-xl bg-[#FE3838] px-8 py-4 text-xs font-bold tracking-wider md:mt-8 md:w-auto lg:mt-14 lg:text-sm lg:leading-[24px]"
              >
                LIST YOUR EVENT
              </button>
              {/* </Link> */}
            </div>
          </div>
          <div className="w-full px-4 md:w-5/12">
            <div className="relative z-[1] mx-auto w-full max-w-[530px] pt-8 lg:mr-0">
              <CTImage src={HeroPhone} alt="hero image" className="mx-auto max-w-full" />
            </div>
          </div>
        </div>
      </CTWrapper>
      {/* <EventForm isOpen={isFormOpen} onClose={closeForm} /> */}
    </div>
  );
};

export default HeroSection;
