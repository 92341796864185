import {
  EVENT_CATEGORY,
  EVENT_EXPIRED,
  EVENT_GEOLOCATION,
  EVENT_TRENDING,
  EVENT_ALLEXPIRED,
  EVENT_BY_CATEGORY,
  EVENT_INFO_DETAILS_API,
  EVENT_LIVE,
  EVENT_SEARCH,
  CATEGORY_INFO,
  CATEGORY_BY_EVENT,
  ACTIVE_DATES,
  LIST_EVENT_DATA,
  UPLOAD_IMAGE,
  EVENT_SLIDER,
  APPLY_COUPON,
  ACTIVE_TICKET,
  FETCH_USER,
  UPDATE_PROFILE,
  UPLOAD_PROFILE_PIC,
} from '../../config/endPoints';
import instance from '../../utils/instance';
import {
  ADD_EVENT_NEW_ITEM,
  ADD_NEW_ANSWER,
  EVENT_EXPIRED_FAIL,
  EVENT_EXPIRED_REQUEST,
  EVENT_EXPIRED_SUCCESS,
  EVENT_CATEGORY_FAIL,
  EVENT_CATEGORY_REQUEST,
  EVENT_CATEGORY_SUCCESS,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_ACTIVE_DATES_FAILURE,
  FETCH_ACTIVE_DATES_SUCCESS,
  FETCH_ACTIVE_DATES_REQUEST,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPLOAD_PROFILE_PIC_REQUEST,
  UPLOAD_PROFILE_PIC_SUCCESS,
  UPLOAD_PROFILE_PIC_FAILURE,
  EVENT_GEOLOCATION_FAIL,
  EVENT_GEOLOCATION_REQUEST,
  EVENT_GEOLOCATION_SUCCESS,
  EVENT_INFO_FAIL,
  EVENT_INFO_REQUEST,
  EVENT_INFO_SUCCESS,
  EVENT_LIVE_FAIL,
  EVENT_LIVE_REQUEST,
  EVENT_LIVE_SUCCESS,
  REMOVE_EVENT_NEW_ITEM,
  REMOVE_NEW_ANSWER,
  EVENT_TRENDING_REQUEST,
  EVENT_TRENDING_SUCCESS,
  EVENT_TRENDING_FAIL,
  EVENT_ALLEXPIRED_REQUEST,
  EVENT_ALLEXPIRED_SUCCESS,
  EVENT_ALLEXPIRED_FAIL,
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  SEARCH_EVENT_REQUEST,
  SEARCH_EVENT_SUCCESS,
  SEARCH_EVENT_FAILURE,
  CATEGORY_INFO_FAIL,
  CATEGORY_INFO_REQUEST,
  CATEGORY_INFO_SUCCESS,
  CATEGORY_BY_EVENT_REQUEST,
  CATEGORY_BY_EVENT_SUCCESS,
  CATEGORY_BY_EVENT_FAIL,
  LIST_EVENT_FAIL,
  LIST_EVENT_REQUEST,
  LIST_EVENT_SUCCESS,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  EVENT_SLIDER_FAIL,
  EVENT_SLIDER_REQUEST,
  EVENT_SLIDER_SUCCESS,
  CATEGORY1_BY_EVENT_FAIL,
  CATEGORY1_BY_EVENT_REQUEST,
  CATEGORY1_BY_EVENT_SUCCESS,
  FETCH_ACTIVE_TICKETS_FAILURE,
  FETCH_ACTIVE_TICKETS_SUCCESS,
  FETCH_ACTIVE_TICKETS_REQUEST,
} from '../constants';

export const getInfoEvent = (eventCode) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_INFO_REQUEST });
    const data = await instance({
      method: 'GET',
      url: EVENT_INFO_DETAILS_API + eventCode,
    });
    dispatch({
      type: EVENT_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: EVENT_INFO_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'EVENT_INFO_ERROR',
      },
    });
  }
};

export const getInfoCategory = (categoryName) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_INFO_REQUEST });
    const data = await instance({
      method: 'GET',
      url: CATEGORY_INFO + categoryName,
    });
    dispatch({
      type: CATEGORY_INFO_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_INFO_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'CATEGORY_INFO_ERROR',
      },
    });
  }
};

// export const searchEvent =
//   ({ queryString, coordinates, skip, limit }) =>
//   async (dispatch) => {
//     const queryParams = [`limit=${limit}`];
//     if (queryString) queryParams.push(`queryString=${queryString}`);
//     if (coordinates) queryParams.push(`coordinates=${coordinates}`);
//     if (skip !== undefined) queryParams.push(`skip=${skip}`);
//     const newParams = queryParams.join('&');
//     try {
//       dispatch({ type: SEARCH_EVENT_REQUEST });

//       const data = await instance({
//         method: 'GET',
//         url: EVENT_SEARCH + `?${newParams}`,
//       });
//       dispatch({
//         type: SEARCH_EVENT_SUCCESS,
//         payload: data?.data,
//       });
//     } catch (error) {
//       dispatch({
//         type: SEARCH_EVENT_FAILURE,
//         payload: {
//           message: error?.response?.data?.message,
//           code: 'EVENT_SEARCH_ERROR',
//         },
//       });
//     }
//   };
export const searchEvent =
  ({ queryString, coordinates }) =>
  async (dispatch) => {
    const queryParams = [];
    //    if (queryString) queryParams.push(`queryString=${queryString}`)
    // if (coordinates) queryParams.push(`coordinates=${coordinates}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: SEARCH_EVENT_REQUEST });
      const data = await instance({
        method: 'POST',
        url: EVENT_SEARCH + `?${newParams}`,
        data: { queryString, coordinates },
      });
      dispatch({
        type: SEARCH_EVENT_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_EVENT_FAILURE,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_SEARCH_ERROR',
        },
      });
    }
  };

export const getEventGeoLocation =
  ({ coordinates, skip, limit }) =>
  async (dispatch) => {
    const queryParams = [`limit=${limit}`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_GEOLOCATION_REQUEST });

      const data = await instance({
        method: 'POST',
        url: EVENT_GEOLOCATION + `?${newParams}`,
        data: { coordinates },
      });
      dispatch({
        type: EVENT_GEOLOCATION_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_GEOLOCATION_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_GEOLOCATION_ERROR',
        },
      });
    }
  };
export const applyCouponRequest =
  ({ couponCode, totalAmount }) =>
  async (dispatch) => {
    try {
      dispatch({ type: APPLY_COUPON_REQUEST });

      const data = await instance({
        method: 'POST',
        url: APPLY_COUPON,
        data: { couponCode, totalAmount },
      });
      dispatch({
        type: APPLY_COUPON_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: APPLY_COUPON_FAILURE,
        payload: {
          message: error?.response?.data?.message,
          code: 'APPLY_COUPON_ERROR',
        },
      });
    }
  };

export const fetchProfile = () => async (dispatch) => {
  dispatch({ type: FETCH_PROFILE_REQUEST });
  try {
    const response = await instance(FETCH_USER);
    const data = await response.json();
    dispatch({ type: FETCH_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_PROFILE_FAILURE, payload: error.message });
  }
};

export const updateProfile = (profileData) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_REQUEST });
  try {
    const response = await instance(UPDATE_PROFILE, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(profileData),
    });
    const data = await response.json();
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_FAILURE, payload: error.message });
  }
};

export const uploadProfilePic = (file, callback) => async (dispatch) => {
  dispatch({ type: UPLOAD_PROFILE_PIC_REQUEST });
  try {
    // Step 1: Get the pre-signed URL
    const response = await instance(UPLOAD_PROFILE_PIC);
    const { url } = await response.json();
    // console.log(url,"url")
    // Step 2: Use the pre-signed URL to upload the file
    await instance(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: file,
    });

    // Step 3: Update the state with the new profile picture URL
    const profilePicUrl = url.split('?')[0];
    console.log(profilePicUrl, 'com');
    dispatch({ type: UPLOAD_PROFILE_PIC_SUCCESS, payload: profilePicUrl });
    callback(profilePicUrl);
  } catch (error) {
    dispatch({ type: UPLOAD_PROFILE_PIC_FAILURE, payload: error.message });
  }
};

export const getEventSlider =
  ({ coordinates, skip, limit }) =>
  async (dispatch) => {
    const queryParams = [`limit=${limit}`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_SLIDER_REQUEST });

      const data = await instance({
        method: 'POST',
        url: EVENT_SLIDER + `?${newParams}`,
        data: { coordinates },
      });
      dispatch({
        type: EVENT_SLIDER_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_SLIDER_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_SLIDER_ERROR',
        },
      });
    }
  };
export const getCategoryEvent =
  ({ categoryName, coordinates, skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: CATEGORY_BY_EVENT_REQUEST });

      const data = await instance({
        method: 'POST',
        url: CATEGORY_BY_EVENT + `?${newParams}`,
        data: { categoryName, coordinates },
      });
      dispatch({
        type: CATEGORY_BY_EVENT_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_BY_EVENT_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'CATEGORY_BY_EVENT_ERROR',
        },
      });
    }
  };
export const getCategory1Event =
  ({ categoryName, coordinates, skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: CATEGORY1_BY_EVENT_REQUEST });

      const data = await instance({
        method: 'POST',
        url: CATEGORY_BY_EVENT + `?${newParams}`,
        data: { categoryName, coordinates },
      });
      dispatch({
        type: CATEGORY1_BY_EVENT_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY1_BY_EVENT_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'CATEGORY1_BY_EVENT_ERROR',
        },
      });
    }
  };
export const getEventTrending =
  ({ coordinates, skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_TRENDING_REQUEST });

      const data = await instance({
        method: 'POST',
        url: EVENT_TRENDING + `?${newParams}`,
        data: { coordinates },
      });
      dispatch({
        type: EVENT_TRENDING_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_TRENDING_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_TRENDING_ERROR',
        },
      });
    }
  };
export const getEventAllExpired =
  ({ coordinates, skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (coordinates) queryParams.push(`coordinates=${coordinates}`);
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_ALLEXPIRED_REQUEST });

      const data = await instance({
        method: 'GET',
        url: EVENT_ALLEXPIRED + `?${newParams}`,
      });
      dispatch({
        type: EVENT_ALLEXPIRED_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ALLEXPIRED_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_ALLEXPIRED_ERROR',
        },
      });
    }
  };
export const fetchEventbyCategory =
  ({ categoryId, coordinates }) =>
  async (dispatch) => {
    const queryParams = [];
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: FETCH_EVENTS_REQUEST });
      const data = await instance({
        method: 'POST',
        url: EVENT_BY_CATEGORY + `${newParams}`,
        data: { categoryId, coordinates },
      });
      dispatch({
        type: FETCH_EVENTS_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_EVENTS_FAILURE,
        payload: {
          message: error?.response?.data?.message,
          code: 'FETCH_EVENTS_ERROR',
        },
      });
    }
  };

export const getEventCategory =
  ({ coordinates, skip = 0 }) =>
  async (dispatch) => {
    const queryParams = [`limit=6`];
    if (coordinates) queryParams.push(`coordinates=${coordinates}`);
    if (typeof skip !== 'undefined') queryParams.push(`skip=${skip}`);
    //    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_CATEGORY_REQUEST });

      const data = await instance({
        method: 'GET',
        url: EVENT_CATEGORY + `?${newParams}`,
      });
      dispatch({
        type: EVENT_CATEGORY_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_CATEGORY_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_CATEGORY_ERROR',
        },
      });
    }
  };
export const getEventExpired =
  ({ skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_EXPIRED_REQUEST });

      const data = await instance({
        method: 'GET',
        url: EVENT_EXPIRED + `?${newParams}`,
      });
      dispatch({
        type: EVENT_EXPIRED_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_EXPIRED_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_EXPIRED_ERROR',
        },
      });
    }
  };
export const getEventLive =
  ({ skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_LIVE_REQUEST });

      const data = await instance({
        method: 'GET',
        url: EVENT_LIVE + `?${newParams}`,
      });
      dispatch({
        type: EVENT_LIVE_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_LIVE_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_FAIL_ERROR',
        },
      });
    }
  };
export const fetchActiveDates =
  ({ eventId }) =>
  async (dispatch) => {
    const queryParams = [];
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: FETCH_ACTIVE_DATES_REQUEST });
      const data = await instance({
        method: 'POST',
        url: ACTIVE_DATES + `${newParams}`,
        data: { eventId },
      });
      dispatch({
        type: FETCH_ACTIVE_DATES_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ACTIVE_DATES_FAILURE,
        payload: {
          message: error?.response?.data?.message,
          code: 'FETCH_ACTIVE_DATES_ERROR',
        },
      });
    }
  };

export const fetchActiveTickets =
  ({ date, eventId }) =>
  async (dispatch) => {
    const queryParams = [];
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: FETCH_ACTIVE_TICKETS_REQUEST });
      const data = await instance({
        method: 'POST',
        url: ACTIVE_TICKET + `${newParams}`,
        data: { date, eventId },
      });
      dispatch({
        type: FETCH_ACTIVE_TICKETS_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ACTIVE_TICKETS_FAILURE,
        payload: {
          message: error?.response?.data?.message,
          code: 'FETCH_ACTIVE_TICKETS_ERROR',
        },
      });
    }
  };

export const addEventNewItem = (props) => async (dispatch) => {
  dispatch({
    type: ADD_EVENT_NEW_ITEM,
    payload: props,
  });
};

export const removeEventItem = () => async (dispatch) => {
  dispatch({
    type: REMOVE_EVENT_NEW_ITEM,
  });
};

export const addNewAnswer = (props) => async (dispatch) => {
  dispatch({
    type: ADD_NEW_ANSWER,
    payload: props,
  });
};

export const removeNewAnswer = () => async (dispatch) => {
  dispatch({
    type: REMOVE_NEW_ANSWER,
  });
};

export const sendFormData = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LIST_EVENT_REQUEST });
    const data = await instance({
      method: 'POST',
      url: LIST_EVENT_DATA,
      data: formData,
    });
    dispatch({
      type: LIST_EVENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_EVENT_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'LIST_EVENT_ERROR',
      },
    });
  }
};

export const uploadImage = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_IMAGE_REQUEST });
    const response = await instance({
      method: 'POST',
      url: UPLOAD_IMAGE,
      data: data,
    });

    if (!response || !response.data) {
      throw new Error('Invalid response received');
    }

    dispatch({
      type: UPLOAD_IMAGE_SUCCESS,
      payload: response.data,
    });

    const presignedUrl = response.data.presignedUrl;

    if (!presignedUrl) {
      throw new Error('Presigned URL not found in response');
    }

    await axios.put(presignedUrl, data.file);
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGE_FAIL,
      payload: {
        message: error?.response?.data?.message || error.message,
        code: 'UPLOAD_IMAGE_ERROR',
      },
    });
  }
};
